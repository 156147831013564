import React, {Component} from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import {BrowserRouter as Router, Route} from "react-router-dom";
import { withLocalize} from "react-localize-redux";
import globalTranslations from "./translations/global.json";
import './css/bootstrap.min.css';
import './css/index.css';
import Header from './layouts/common/header';
import Footer from './layouts/common/footer';
import Home from './layouts/home';
import Bulgaria from './layouts/bulgaria/bulgaria';
import Greece from './layouts/greece/greece';
import Cyprus from './layouts/cyprus/cyprus';
import About from './layouts/about/about';

class Routes extends Component {

  constructor(props) {
    super(props);
    this.props.initialize({
      languages: [
        {
          name: "English",
          code: "en"
        },  {
          name: "Bulgarian",
          code: "bg"
        }, {
          name: "Greek",
          code: "el"
        }
      ],
      defaultLanguage: 'en',
      translation: globalTranslations,
      options: {
        defaultLanguage: "en",
        renderToStaticMarkup
      }
    });
  }

  render() {
    return (
        <Router>
          <div>
            <Header/>
            <Route exact path="/" component={Home}/>
            <Route path="/Bulgaria" component={Bulgaria}/>
            <Route path="/Greece" component={Greece}/>
            <Route path="/Cyprus" component={Cyprus}/>
            <Route path="/About" component={About}/>
            <Footer/>
          </div>
        </Router>
    );
  }
}

export default withLocalize(Routes);
