import {Control} from 'ol/control.js';
import './stationinfo.css';

let ol_station_info = (function(Control) {
  function StationInfoControl(opt_options) {
    let options = opt_options || {};

    let element = document.createElement('div');
    element.setAttribute("id", "ol-stationInfoDiv");
    element.className = 'ol-station-info ol-unselectable ol-control';

    Control.call(this, {
      element: element,
      target: options.target
    });
  }

  if ( Control ) StationInfoControl.__proto__ = Control;
  StationInfoControl.prototype = Object.create( Control && Control.prototype );
  StationInfoControl.prototype.constructor = StationInfoControl;

  return StationInfoControl;
}(Control));

ol_station_info.prototype.add = function(featuresToshow) {
  let html = '';

  featuresToshow.forEach(function(item){
    if(item.title === 'URL'){
      html += '<tr><td>' + item.title + '</td><td><a href="http://penteli.meteo.gr/stations/' + item.value + '" target="_blank" style="font-size: large;">➥</a></td></tr>'
    }else if(item.title === 'photo'){
      html += '<td colspan="2" align="center"><a href="' + item.value + '" target="_blank" style="font-size: large;"><img border="0" alt="DISARMimage" src="' + item.value + '" width="150" height="150"></a></td></tr>'
    }else{
      html += '<tr><td>' + item.title + '</td><td>' + item.value + '</td></tr>'
    }
  });

  let div = document.getElementById('ol-stationInfoDiv');
  div.innerHTML = '<div><span id=\'ol-stationInfoDiv-close\'>x</span><table className="ol-station-info-table">' + html + '</table></div>';
  div.classList.add('ol-station-info-show');

  document.getElementById('ol-stationInfoDiv-close').onclick = function() {
    let div = document.getElementById('ol-stationInfoDiv');
    div.classList.remove('ol-station-info-show');
  };

}


export default ol_station_info
