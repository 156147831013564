import React, {Component} from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import bulgaria from '../img/bulgaria.png';
import greece from '../img/greece.png';
import cyprus from '../img/cyprus.png';
import {Link} from 'react-router-dom';

class Home extends Component {

  render() {
    return (<div>
      <div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 className="display-4">DISARM</h1>
        <p className="lead">Drought and fIre ObServatory and eArly waRning systeM</p>
      </div>
      <div className="container">
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 shadow-sm">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal"><Translate id="bulgaria"/></h4>
            </div>
            <div className="card-body">
              <Link to="/Bulgaria">
                <img src={bulgaria} width="100%" alt="Bulgaria"/>
                <button type="button" className="btn btn-lg btn-block btn-outline-primary"><Translate id="enter"/></button>
              </Link>
            </div>
          </div>
          <div className="card mb-4 shadow-sm">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal"><Translate id="greece"/></h4>
            </div>
            <div className="card-body">
              <Link to="/Greece">
                <img src={greece} width="100%" alt="Greece"/>
                <button type="button" className="btn btn-lg btn-block btn-outline-primary"><Translate id="enter"/></button>
              </Link>
            </div>
          </div>
          <div className="card mb-4 shadow-sm">
            <div className="card-header">
              <h4 className="my-0 font-weight-normal"><Translate id="cyprus"/></h4>
            </div>
            <div className="card-body">
              <Link to="/Cyprus">
                <img src={cyprus} width="100%" alt="Cyprus"/>
                <button type="button" className="btn btn-lg btn-block btn-outline-primary"><Translate id="enter"/></button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

export default withLocalize(Home);
