import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { withLocalize, Translate } from "react-localize-redux";
import ChangeLanguage from "./changeLanguage";
import logo from '../../img/logo.png';

class Header extends Component {
  render() {
    return (
      <div className="sticky-top d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
          <h5 className="my-0 mr-md-auto font-weight-normal"><a href="http://disarmfire.eu/"><img src={logo} alt=""/></a></h5>
        <nav className="my-2 my-md-0 mr-md-3">
          <ChangeLanguage/>
          <Link to="/" className="p-2 text-dark"><Translate id="home"/></Link>
          <Link to="/Bulgaria" className="p-2 text-dark"><Translate id="bulgaria"/></Link>
          <Link to="/Greece" className="p-2 text-dark"><Translate id="greece"/></Link>
          <Link to="/Cyprus" className="p-2 text-dark"><Translate id="cyprus"/></Link>
          <a href="http://disarmfire.eu/" className="p-2 text-dark"><Translate id="about"/></a>
        </nav>
      </div>
      );
  }
}

export default withLocalize(Header);
