import TileLayer from 'ol/layer/Tile.js';
import OSM from 'ol/source/OSM';
import TileWMS from 'ol/source/TileWMS.js';
import ImageLayer from "ol/layer/Image";
import ImageWMS from "ol/source/ImageWMS";

import XYZ from 'ol/source/XYZ';

const layer_station = new TileWMS({
    url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
    params: {
        'LAYERS': 'fire_indices_cy',
        'TILED': true
    },
    serverType: 'geoserver',
    crossOrigin: 'anonymous'
});

const layers_stations = [
    // new TileLayer({id: "openstreetmap", title: "OpenStreetMap", source: new OSM()}),
    new TileLayer({
        id: "openstreetmap", title: "OpenStreetMap",
        source: new XYZ({
            url: 'https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw'
        })
    }),

    new TileLayer({
        id: "temperature",
        title: "temperature",
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'temp_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new TileLayer({
        id: "dailyMinTemperature",
        title: "Daily Min Temperature (°C)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'daily_min_temp_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new TileLayer({
        id: "dailyMaxTemperature",
        title: "Daily Max Temperature (°C)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'daily_max_temp_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new TileLayer({
        id: "dew",
        title: "Dew (°C)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'dew_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new TileLayer({
        id: "hum",
        title: "hum",
        unit: "%",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'hum_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new TileLayer({
        id: "bar",
        title: "Bar (hPa)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'bar_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new TileLayer({
        id: "windSpeed",
        title: "Wind Speed (km/hr)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'w_speed_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new TileLayer({
        id: "windDirection",
        title: "Wind Direction (°)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'w_dir_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new TileLayer({
        id: "maxWindSpeed",
        title: "Max Wind Speed (km/hr)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'hi_w_speed_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new TileLayer({
        id: "dailyRain",
        title: "Daily Rain (mm)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'daily_rain_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new TileLayer({
        id: "monthlyRain",
        title: "Monthly Rain (mm)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'monthly_rain_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new TileLayer({
        id: "solarRad",
        title: "Solar Radiation (W/m2)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'solar_rad_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new TileLayer({
        id: "Canadian Fire Weather Index (CFWI)",
        title: "Canadian Fire Weather Index (CFWI)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'ffwi_index_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new TileLayer({
        id: "simple Sharples Fire Danger Index (sSFDI)",
        title: 'simple Sharples Fire Danger Index (sSFDI)',
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'ffdi_index_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new TileLayer({
        id: "Fosberg Fire Weather Index (FFWI)",
        title: "Fosberg Fire Weather Index (FFWI)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'gfdi5_index_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new TileLayer({
        id: "Swedish Angstrom Fire Weather Index (SAI)",
        title: "Swedish Angstrom Fire Weather Index (SAI)",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'f_index_cy',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new TileLayer({
        id: "userreports",
        title: "userreports",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'userreports',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    })

];

const layers_forecasts = [
    new TileLayer({id: "openstreetmap", title: "OpenStreetMap", source: new XYZ({
            url: 'https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw'
        })}),

    new ImageLayer({
        id: "temp_near_surface",
        title: "temp_near_surface",
        unit: "°C",
        visible: true,
        baseLayer: true,
        extent: [
            3567371, 4062170, 3890845, 4292524
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'CYtwb2',
                'TILED': false
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new ImageLayer({
        id: "temp_2_m",
        title: "temp_2_m",
        unit: "°C",
        visible: false,
        baseLayer: true,
        extent: [
            3567371, 4062170, 3890845, 4292524
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'CYt2m',
                'TILED': false
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "wind",
        title: "wind",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            567371, 4062170, 3890845, 4292524
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'CYwind',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new ImageLayer({
        id: "humidity",
        title: "humidity",
        unit: "%",
        visible: false,
        baseLayer: true,
        extent: [
            3567371, 4062170, 3890845, 4292524
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'CYrh2',
                'TILED': false
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new ImageLayer({
        id: "rain",
        title: "rain",
        unit: "mm",
        visible: false,
        baseLayer: true,
        extent: [
            3567371, 4062170, 3890845, 4292524
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'CYrain',
                'TILED': false
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new ImageLayer({
        id: "cfwi",
        title: "cfwi",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'CYcfwi',
                'TILED': false
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new ImageLayer({
        id: "sai",
        title: "sai",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'CYsai',
                'TILED': false
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new ImageLayer({
        id: "haines",
        title: "haines",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'CYhaines',
                'TILED': false
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new ImageLayer({
        id: "ffwi",
        title: "ffwi",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'CYffwi',
                'TILED': false
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new ImageLayer({
        id: "sfdi",
        title: "sfdi",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            3568371, 4072170, 3880845, 4282524
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_CY/wms',
            params: {
                'LAYERS': 'CYsfdi',
                'TILED': false
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),

    new TileLayer({
        id: "cyprus",
        title: "cyprus",
        baseLayer: false,
        // extent: [
        //     3568371, 4072170, 3880845, 4282524
        // ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'countries',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    })
];


const layers_climate_forecasts = [

    new TileLayer({id: "openstreetmap", title: "OpenStreetMap", source: new XYZ({
            url: 'https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw'
        })})

];

const layers_climate_model = [

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },


    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_rv",
                title: "f_191_43_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_191_43_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            }),
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },



    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

//////

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },



    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },


//////

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },



    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    3568371, 4072170, 3880845, 4282524
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },


];

export default {
    xy: [3714958, 4173444],
    zoom: 9,
    extent: [3568371, 4072170, 3880845, 4282524],
    layer_station,
    layers_stations,
    layers_forecasts,
    layers_climate_forecasts,
    layers_climate_model,
    stations_json: 'http://disarmfire.eu/geoserver/DISARM_CY/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=DISARM_CY%3Afire_indices_cy&maxFeatures=50&outputFormat=application%2Fjson',
    proxy: 'http://map.disarmfire.eu/api/index.php?'
}
