import React from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import EN from "../../img/en.svg";
import BG from "../../img/bg.svg";
import EL from "../../img/el.svg";
import fb from "../../img/facebook_logo_2019.svg";

const ChangeLanguage = ({languages, activeLanguage, setActiveLanguage}) => {
    const getClass = (languageCode) => {
        return languageCode === activeLanguage.code ? 'active' : ''
    };

    return (
        <div align="right">
            <a href="https://www.facebook.com/DroughtAndFireInterreg/" target="new" className="p-2 text-dark"><img src={fb} alt="el" height="18" border="0" style={{borderColor: "darkgray", borderStyle: "solid"}}/></a>
            &nbsp;&nbsp;
            <button onClick={() => setActiveLanguage("en")} style={{borderWidth: "0", backgroundColor: "transparent"}} ><img src={EN} alt="en" height="16" border="0" style={{borderColor: "darkgray", borderStyle: "solid"}}/></button>
            <button onClick={() => setActiveLanguage("bg")} style={{borderWidth: "0", backgroundColor: "transparent"}} ><img src={BG} alt="bg" height="16" border="1" style={{borderColor: "darkgray", borderStyle: "solid"}}/></button>
            <button onClick={() => setActiveLanguage("el")} style={{borderWidth: "0", backgroundColor: "transparent"}}><img src={EL} alt="el" height="16" border="0" style={{borderColor: "darkgray", borderStyle: "solid"}}/></button>
        </div>
    );
};

export default withLocalize(ChangeLanguage);
