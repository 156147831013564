import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withLocalize, Translate } from "react-localize-redux";

class About extends Component {
  render() {
    return (
      <div>

        <div className="container">

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/" className="p-2 text-dark"><Translate id="home"/></Link></li>
              <li className="breadcrumb-item active" aria-current="page"><Translate id="about"/></li>
            </ol>
          </nav>

          <h5><Translate id="about_text.FFWI_title"/></h5>
          <p style={{textAlign: "justify"}}><Translate id="about_text.FFWI"/></p>

          <h5><Translate id="about_text.FFDI_title"/></h5>
          <p style={{textAlign: "justify"}}><Translate id="about_text.FFDI"/></p>

          <h5><Translate id="about_text.GFDI5_title"/></h5>
          <p style={{textAlign: "justify"}}><Translate id="about_text.GFDI5"/></p>

          <h5><Translate id="about_text.F_title"/></h5>
          <p style={{textAlign: "justify"}}><Translate id="about_text.F"/></p>

          <h5><Translate id="about_text.Fuel_title"/></h5>
          <p style={{textAlign: "justify"}}><Translate id="about_text.Fuel"/></p>

          <h5><Translate id="about_text.CoFRI_title"/></h5>
          <p style={{textAlign: "justify"}}><Translate id="about_text.CoFRI"/></p>

        </div>
      </div>
    );
  }
}

export default withLocalize(About);
