import TileLayer from 'ol/layer/Tile.js';
import OSM from 'ol/source/OSM';
import TileWMS from 'ol/source/TileWMS.js';
import ImageLayer from "ol/layer/Image";
import ImageWMS from "ol/source/ImageWMS";

const layer_station = new TileWMS({
    url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
    params: {
        'LAYERS': 'fire_indices_bg',
        'TILED': true
    },
    serverType: 'geoserver',
    crossOrigin: 'anonymous'
});

const layers_stations = [
    new TileLayer({id: "openstreetmap", title: "OpenStreetMap", source: new OSM()}),

    new TileLayer({
        id: "temperature",
        title: "temperature",
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'temp_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "dailyMinTemperature",
        title: "Daily Min Temperature (°C)",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'daily_min_temp_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "dailyMaxTemperature",
        title: "Daily Max Temperature (°C)",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'daily_max_temp_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "dew",
        title: "Dew (°C)",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'dew_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "hum",
        title: "hum",
        unit: "%",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'hum_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "bar",
        title: "Bar (hPa)",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'bar_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "windSpeed",
        title: "Wind Speed (km/hr)",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'w_speed_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "windDirection",
        title: "Wind Direction (°)",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'w_dir_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "maxWindSpeed",
        title: "Max Wind Speed (km/hr)",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'hi_w_speed_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "dailyRain",
        title: "Daily Rain (mm)",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'daily_rain_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "monthlyRain",
        title: "Monthly Rain (mm)",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'monthly_rain_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "solarRad",
        title: "Solar Radiation (W/m2)",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'solar_rad_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "F1",
        title: "F1",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'f_index_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "F2",
        title: 'F2',
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'f2_index_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "F3",
        title: "F3",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'f3_index_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "complexfirerisk",
        title: "complexfirerisk",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_BG/wms',
            params: {
                'LAYERS': 'complexfirerisk_index_bg',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "userreports",
        title: "userreports",
        visible: false,
        baseLayer: true,
        extent: [
            2453594, 5005243, 3292567, 5581272
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'userreports',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    })

];

const layers_forecasts = [new TileLayer({source: new OSM()})];

const layers_climate_forecasts = [

    new TileLayer({id: "openstreetmap", title: "OpenStreetMap", source: new OSM()})

];

const layers_climate_model = [

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },


    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_rv",
                title: "f_191_43_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_191_43_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            }),
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },



    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

//////

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },



    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },


//////

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },



    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2453594, 5005243, 3292567, 5581272
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },


];

export default {
    xy: [2930561, 5274301],
    zoom: 8,
    extent: [2453594, 5005243, 3292567, 5581272],
    layer_station,
    layers_stations,
    layers_forecasts,
    layers_climate_forecasts,
    layers_climate_model,
    stations_json: 'http://disarmfire.eu/geoserver/DISARM_BG/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=DISARM_BG%3Afire_indices_bg&maxFeatures=50&outputFormat=application%2Fjson',
    proxy: 'http://map.disarmfire.eu/api/index.php?'
}
