import React, {Component} from 'react';
import bg from '../../img/bg.png';
import cy_instit from '../../img/cy_instit.png';
import cy_met_meteo from '../../img/cy_met_meteo.png';
import noa from '../../img/noa.png';
import uoa from '../../img/uoa.gif';
import { withLocalize, Translate } from "react-localize-redux";

class Footer extends Component {

  render() {
    return (
        <footer className="pt-4 my-md-5 pt-md-5 border-top container text-center">
          <div className="row" style={{textAlign: "center"}}>
            <div className="col-6 col-md"><a href="http://www.noa.gr/" target="new"><img src={noa} alt="" width="60%"/><p><Translate id="noa"/></p></a></div>
            <div className="col-6 col-md"><a href="http://www.meteo.bg/" target="new"><img src={bg} alt="" width="60%"/><p><Translate id="bulgarianNationalInstitute"/></p></a></div>
            <div className="col-6 col-md"><a href="http://www.moa.gov.cy/moa/ms/ms.nsf/DMLindex_en/DMLindex_en?opendocument" target="new"><img src={cy_met_meteo} alt="" width="60%"/><p><Translate id="cyprusDepartmentMeteorology"/></p></a></div>
            <div className="col-6 col-md"><a href="https://en.uoa.gr/" target="new"><img src={uoa} alt="" width="60%"/><p><Translate id="uoa"/></p></a></div>
            <div className="col-6 col-md"><a href="https://www.cyi.ac.cy/" target="new"><img src={cy_instit} alt="" width="60%"/><p><Translate id="cyprusInstituteLtd"/></p></a></div>
          </div>
        </footer>);
  }
}

export default withLocalize(Footer);
