import {Control} from 'ol/control.js';
import './legend.css';

let ol_legend_info = (function(Control) {
    function LegendInfoControl(opt_options) {
        let options = opt_options || {};

        let element = document.createElement('div');
        element.setAttribute("id", "ol-legendInfoDiv-2");
        element.className = 'ol-legend-info ol-unselectable ol-control';

        Control.call(this, {
            element: element,
            target: options.target
        });
    }

    if ( Control ) LegendInfoControl.__proto__ = Control;
    LegendInfoControl.prototype = Object.create( Control && Control.prototype );
    LegendInfoControl.prototype.constructor = LegendInfoControl;

    return LegendInfoControl;
}(Control));

export default ol_legend_info
