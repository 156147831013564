import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import OSM from 'ol/source/OSM';

const layer_station = new TileWMS({
    url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
    params: {
        'LAYERS': 'fire_indices',
        'TILED': true
    },
    serverType: 'geoserver',
    crossOrigin: 'anonymous'
});

const layers_stations = [
    new TileLayer({id: "openstreetmap", title: "OpenStreetMap", source: new OSM()}),

    new TileLayer({
        id: "temperature",
        title: "temperature",
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'temp',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "dailyMinTemperature",
        title: "Daily Min Temperature (°C)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'daily_min_temp',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "dailyMaxTemperature",
        title: "Daily Max Temperature (°C)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'daily_max_temp',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "hum",
        title: "Humidity (%)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'hum',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "dew",
        title: "Dew (°C)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'dew',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "bar",
        title: "Bar (hPa)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'bar',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "windSpeed",
        title: "Wind Speed (km/hr)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'w_speed',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "windDirection",
        title: "Wind Direction (°)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'w_dir',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "maxWindSpeed",
        title: "Max Wind Speed (km/hr)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'hi_w_speed',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "dailyMaxWindSpeed",
        title: "Daily Max Wind Speed (km/hr)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'daily_max_w_speed',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "min10Rain",
        title: "10 minutes Rain (mm)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'min10_rain',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "dailyRain",
        title: "Daily Rain (mm)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'calc_daily_rain',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "monthlyRain",
        title: "Monthly Rain (mm)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'monthly_rain',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "yearlyRain",
        title: "Yearly Rain (mm)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'daily_rain',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "solarRad",
        title: "Solar Radiation (W/m2)",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'solar_rad',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "FFWI",
        title: "FFWI",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'ffwi_index',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "FFDI",
        title: 'FFDI',
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'ffdi_index',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "GFDI5",
        title: "GFDI5",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'gfdi5_index',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "F",
        title: "F",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'f_index',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new TileLayer({
        id: "Fuel_Moisture",
        title: "Fuel_Moisture",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'fuel_moisture',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "userreports",
        title: "userreports",
        visible: false,
        baseLayer: true,
        extent: [
             2100283, 4115075, 3350664, 5159510
        ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'userreports',
                'TILED': true
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    })
];

const layers_forecasts = [
    new TileLayer({id: "openstreetmap", title: "OpenStreetMap", source: new OSM()}),

    new ImageLayer({
        id: "temp",
        title: "temp",
        unit: "°C",
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMEStemperature',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new ImageLayer({
        id: "wind",
        title: "wind",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMESwind',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new ImageLayer({
        id: "rain",
        title: "rain",
        unit: "mm",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMESrain',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new ImageLayer({
        id: "humidity",
        title: "humidity",
        unit: "%",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMEShumidity',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new ImageLayer({
        id: "HERMESltng",
        title: "HERMESltng",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMESltng',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new ImageLayer({
        id: "HERMEScape",
        title: "HERMEScape",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMEScape',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new ImageLayer({
        id: "cfwi",
        title: "cfwi",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMEScfwi',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new ImageLayer({
        id: "nesterov",
        title: "nesterov",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMESnesterov',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new ImageLayer({
        id: "HERMESfosberg",
        title: "HERMESfosberg",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMESfosberg',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new ImageLayer({
        id: "HERMEShaines",
        title: "HERMEShaines",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMEShaines',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new ImageLayer({
        id: "HERMESmwnesterov",
        title: "HERMESmwnesterov",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMESmwnesterov',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new ImageLayer({
        id: "HERMESzi",
        title: "HERMESzi",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMESzi',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),
    new ImageLayer({
        id: "HERMESangstrom",
        title: "HERMESangstrom",
        unit: "",
        visible: false,
        baseLayer: true,
        extent: [
            2100283, 4115075, 3350664, 5159510
        ],
        source: new ImageWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'HERMESangstrom',
                'TILED': false
            },
            serverType: 'geoserver',
            // Countries have transparency, so do not fade tiles:
            transition: 0
        })
    }),

    new TileLayer({
        id: "greece",
        title: "greece",
        baseLayer: false,
        // extent: [
        //     2100283, 4115075, 3350664, 5159510
        // ],
        source: new TileWMS({
            url: 'http://disarmfire.eu/geoserver/DISARM_GR/wms',
            params: {
                'LAYERS': 'countries',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    })
];

const layers_climate_forecasts = [

    new TileLayer({id: "openstreetmap", title: "OpenStreetMap", source: new OSM()})

];

const layers_climate_model = [

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_rv",
                title: "f_191_43_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_191_43_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            }),
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_dif",
                title: "f_191_43_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_191_43_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_rv",
                title: "f_43_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_43_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_dif",
                title: "f_43_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_43_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_rv",
                title: "f_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_dif",
                title: "f_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_f_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },


    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_rv",
                title: "f_191_43_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_191_43_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            }),
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_dif",
                title: "f_191_43_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_191_43_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_rv",
                title: "f_43_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_43_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_dif",
                title: "f_43_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_43_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_rv",
                title: "f_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_dif",
                title: "f_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_f_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },



    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp45_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'cnrm',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'cnrm_rcp85_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },


//////

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_rv",
                title: "f_191_43_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_191_43_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            }),
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_dif",
                title: "f_191_43_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_191_43_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_rv",
                title: "f_43_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_43_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_dif",
                title: "f_43_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_43_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_rv",
                title: "f_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_dif",
                title: "f_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_f_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_rv",
                title: "f_191_43_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_191_43_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            }),
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_dif",
                title: "f_191_43_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_191_43_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_rv",
                title: "f_43_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_43_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_dif",
                title: "f_43_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_43_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_rv",
                title: "f_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_dif",
                title: "f_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_f_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },



    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp45_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mohc',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mohc_rcp85_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },


//////

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_rv",
                title: "f_191_43_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_191_43_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            }),
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_dif",
                title: "f_191_43_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_191_43_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_rv",
                title: "f_43_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_43_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_dif",
                title: "f_43_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_43_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_rv",
                title: "f_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_dif",
                title: "f_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_f_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_ms_rv",
                title: "f_ms_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_ms_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_ms_dif",
                title: "f_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_113_233_rv",
                title: "f_113_233_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_113_233_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_rv",
                title: "f_191_43_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_191_43_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            }),
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'ModerateFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_113_233_dif",
                title: "f_113_233_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_113_233_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_191_43_dif",
                title: "f_191_43_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_191_43_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_234_38_rv",
                title: "f_234_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_234_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_rv",
                title: "f_43_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_43_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'HigFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_234_38_dif",
                title: "f_234_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_234_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_43_73_dif",
                title: "f_43_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_43_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "f_38_rv",
                title: "f_38_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_38_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_rv",
                title: "f_73_rv",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_73_rv',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'CFWI',
        FireDanger: 'VeryHighFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "f_38_dif",
                title: "f_38_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_38_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            }),
            new ImageLayer({
                id: "f_73_dif",
                title: "f_73_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_f_73_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: false
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_ref",
                title: "ffwi_ms_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_ffwi_ms_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'FFWI',
        FireDanger: 'MeanSummerFireDanger',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "ffwi_ms_dif",
                title: "ffwi_ms_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_ffwi_ms_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_ref",
                title: "sai_gt_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_gt_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnlikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_gt_4_dif",
                title: "sai_gt_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_gt_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_3_4_ref",
                title: "sai_3_4_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_3_4_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceUnfavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_3_4_dif",
                title: "sai_3_4_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_3_4_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_25_3_ref",
                title: "sai_25_3_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_25_3_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_25_3_dif",
                title: "sai_25_3_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_25_3_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_2_25_ref",
                title: "sai_2_25_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_2_25_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireConditionsMoreFavourable',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_2_25_dif",
                title: "sai_2_25_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_2_25_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_ref",
                title: "sai_lt_2_ref",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_lt_2_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SAI',
        FireDanger: 'FireOccurrenceVeryLikely',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "sai_lt_2_dif",
                title: "sai_lt_2_dif",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_sai_lt_2_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },



    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp45',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp45_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },

    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'ref',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_spi_ref',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },
    {
        ClimateModel: 'mpi',
        EmissionScenario: 'rcp85',
        FireDangerIndicator: 'SPI',
        FireDanger: 'DryConditions',
        Period: 'diff',
        layers: [
            new ImageLayer({
                id: "SPI_layer",
                title: "SPI_layer",
                unit: "",
                baseLayer: true,
                extent: [
                    2100283, 4115075, 3350664, 5159510
                ],
                source: new ImageWMS({
                    url: 'http://disarmfire.eu/geoserver/Climate/wms',
                    params: {
                        'LAYERS': 'mpi_rcp85_spi_dif',
                        'TILED': false
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0
                }),
                visible: true
            })
        ]
    },


];

export default {
    xy: [2809281, 4658083],
    zoom: 7,
    extent: [2100283, 4115075, 3350664, 5159510],
    layer_station,
    layers_stations,
    layers_forecasts,
    layers_climate_forecasts,
    layers_climate_model,
    stations_json: 'http://disarmfire.eu/geoserver/DISARM_GR/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=DISARM_GR%3Afire_indices&maxFeatures=500&outputFormat=application%2Fjson',
    legend: 'http://disarmfire.eu/geoserver/DISARM_GR/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=',
    proxy: 'http://map.disarmfire.eu/api/index.php?'
}
