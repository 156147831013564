import {Control} from 'ol/control.js';
import './zoomall.css';

let ol_zoom_all = (function(Control) {

    let options;

    function ZoomallInfoControl(opt_options) {
        options = opt_options || {};

        let button = document.createElement('button');
        button.title = 'Zoom all';
        button.innerHTML = '<div style="font-size: 14px; margin-top: 4px;"><i class="fas fa-expand-arrows-alt"></i></div>';

        let element = document.createElement('div');
        element.className = 'ol-zoom-all ol-unselectable ol-control';
        element.appendChild(button);

        Control.call(this, {
            element: element,
            target: options.target
        });

        button.addEventListener('click', this.handleZoomAll.bind(this), false);
    }

    if ( Control ) ZoomallInfoControl.__proto__ = Control;
    ZoomallInfoControl.prototype = Object.create( Control && Control.prototype );
    ZoomallInfoControl.prototype.constructor = ZoomallInfoControl;

    ZoomallInfoControl.prototype.handleZoomAll = function handleZoomAll () {
        const map = this.getMap();
        map.getView().animate({center: options.xy, zoom: options.zoom + options.extra});
    };

    return ZoomallInfoControl;
}(Control));

export default ol_zoom_all
