import {Control} from 'ol/control.js';
import './modal.css';

let ol_modal_info = (function(Control) {
    function ModalInfoControl(opt_options) {
        let options = opt_options || {};

        let element = document.createElement('div');
        element.setAttribute("id", "ol-modalInfoDiv");
        element.className = 'ol-modal-info ol-unselectable ol-control';

        Control.call(this, {
            element: element,
            target: options.target
        });
    }

    if ( Control ) ModalInfoControl.__proto__ = Control;
    ModalInfoControl.prototype = Object.create( Control && Control.prototype );
    ModalInfoControl.prototype.constructor = ModalInfoControl;

    return ModalInfoControl;
}(Control));

ol_modal_info.prototype.set = function(content) {

    let div = document.getElementById('ol-modalInfoDiv');
    div.innerHTML = "<div><span id='ol-modalInfoDiv-close'>x</span><div class='content'>" + content + "</div></div>";

    document.getElementById('ol-modalInfoDiv-close').onclick = function() {
        let div = document.getElementById('ol-modalInfoDiv');
        div.classList.remove('ol-modal-info-show');
        document.getElementById('map_forecast_mask').style["display"] = "none";
    };

};

ol_modal_info.prototype.show = function() {
    let div = document.getElementById('ol-modalInfoDiv');
    div.classList.add('ol-modal-info-show');
};

ol_modal_info.prototype.hide = function() {
    let div = document.getElementById('ol-modalInfoDiv');
    div.classList.remove('ol-modal-info-show');
};


export default ol_modal_info
