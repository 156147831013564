import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {withLocalize, Translate} from "react-localize-redux";
import config from './config';
import 'ol/ol.css';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import {fromLonLat, toLonLat} from 'ol/proj';
import {defaults as defaultControls, FullScreen} from 'ol/control';
import 'ol-ext/dist/ol-ext.css';
import LayerPopup from 'ol-ext/control/LayerPopup';

import StationInfoControl from '../../ol-custom/stationinfo/stationinfo';
import ZoomAll from '../../ol-custom/zoomall/zoomall';
import LegendInfoControl from '../../ol-custom/legend/legend';
import LegendInfoControl2 from '../../ol-custom/legend/legend2';
import ModalInfoControl from '../../ol-custom/modal/modal';
import LoadingProgress from '../../ol-custom/loadingprogress/loadingprogress';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearchPlus} from '@fortawesome/free-solid-svg-icons';

import { PixelSpinner } from 'react-epic-spinners'

import WMSCapabilities from 'ol/format/WMSCapabilities';
import TimeSlider from '../../ol-custom/timeslider/timeslider'
import moment from 'moment';
import momenttz from 'moment-timezone';

// import {Chart} from "react-charts";
import Chart from 'react-apexcharts'

import '../../css/ol-disarm.css';
import GroupLayer from "ol/layer/Group";
import {Col, Input, Label, Row} from "reactstrap";

class Cyprus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map_stations: null,
            map_stations_LayerPopup_control: null,
            map_climate_forecast: null,
            map_climate_forecast_LayerPopup_control: null,
            map_forecast: null,
            stations: [],
            timestamp: '',
            min: null,
            max: null,
            step: 10800000,
            crosshairValues: [],
            ClimateModel: 'cnrm',
            EmissionScenario: 'rcp45',
            FireDangerIndicator: 'CFWI',
            FireDanger: 'MeanSummerFireDanger',
            Period: 'ref',

            FireDangerOptions: false
        };

        this.layerDIM = [];

        this.setClimateModel = this.setClimateModel.bind(this);
        this.setEmissionScenario = this.setEmissionScenario.bind(this);
        this.setFireDangerIndicator = this.setFireDangerIndicator.bind(this);
        this.setFireDanger = this.setFireDanger.bind(this);
        this.setPeriod = this.setPeriod.bind(this);
    }

    setClimateModel(e) {
        this.setState({
            ClimateModel: e.target.value
        }, function () {
            this.updateLayers()
        })
    }

    setEmissionScenario(e) {
        this.setState({
            EmissionScenario: e.target.value
        }, function () {
            this.updateLayers()
        })
    }

    setFireDangerIndicator(e) {

        let options;
        if(e.target.value === "CFWI"){
            this.setState({
                FireDanger: 'ModerateFireDanger'
            });
            options= <>
                <option value="MeanSummerFireDanger">{this.props.translate('MeanSummerFireDanger')}</option>
                <option value="ModerateFireDanger">{this.props.translate('ModerateFireDanger')}</option>
                <option value="HigFireDanger">{this.props.translate('HigFireDanger')}</option>
                <option value="VeryHighFireDanger">{this.props.translate('VeryHighFireDanger')}</option>
            </>
        }
        if(e.target.value === "FFWI"){
            this.setState({
                FireDanger: 'MeanSummerFireDanger'
            });
            options= <>
                <option selected value="MeanSummerFireDanger">{this.props.translate('MeanSummerFireDanger')}</option>
            </>

        }
        if(e.target.value === "SAI"){
            this.setState({
                FireDanger: 'FireOccurrenceUnlikely'
            });
            options= <>
                <option value="FireOccurrenceUnlikely">{this.props.translate('FireOccurrenceUnlikely')}</option>
                <option value="FireOccurrenceUnfavourable">{this.props.translate('FireOccurrenceUnfavourable')}</option>
                <option value="FireConditionsFavourable">{this.props.translate('FireConditionsFavourable')}</option>
                <option value="FireConditionsMoreFavourable">{this.props.translate('FireConditionsMoreFavourable')}</option>
                <option value="FireOccurrenceVeryLikely">{this.props.translate('FireOccurrenceVeryLikely')}</option>
            </>

        }
        if(e.target.value === "SPI"){
            this.setState({
                FireDanger: 'DryConditions'
            });
            options= <>
                <option value="DryConditions">{this.props.translate('DryConditions')}</option>
            </>

        }

        this.setState({
            FireDangerIndicator: e.target.value,
            FireDangerOptions: options
        }, function () {
            this.updateLayers()
        })
    }

    setFireDanger(e) {
        this.setState({
            FireDanger: e.target.value
        }, function () {
            this.updateLayers()
        })
    }

    setPeriod(e) {
        this.setState({
            Period: e.target.value
        }, function () {
            this.updateLayers()
        })
    }

    updateLayers() {
        let t = this;

        this.state.map_climate_forecast.setLayerGroup(new GroupLayer());
        this.state.map_climate_forecast.addLayer(config.layers_climate_forecasts[0]);

        config.layers_climate_model.forEach(function (ly) {

            if (ly.ClimateModel === t.state.ClimateModel &&
                ly.EmissionScenario === t.state.EmissionScenario &&
                ly.FireDangerIndicator === t.state.FireDangerIndicator &&
                ly.FireDanger === t.state.FireDanger &&
                ly.Period === t.state.Period) {

                ly.layers.forEach((l) => {
                    l.values_.title = t.props.translate(l.get('id'));
                    t.state.map_climate_forecast.addLayer(l);


                    if (l.get('baseLayer') && l.get('visible')) {
                        let layer_name = l.get('source').getParams().LAYERS;
                        let layer_url = l.get('source').url_;

                        let image_source = layer_url + '?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=' + layer_name;

                        let div = document.getElementById('ol-legendInfoDiv-2');
                        div.innerHTML = '<img src="' + image_source + '" alt="Legend" >';
                        div.classList.add('ol-legend-info-show');
                    }

                })


            }
        });

        this.state.map_climate_forecast.getLayerGroup().on('change', function (e) {
            const layers = e.target.get('layers').getArray();
            layers.forEach(function (item) {
                if (item.get('baseLayer') && item.get('visible')) {
                    let layer_name = item.get('source').getParams().LAYERS;
                    let layer_url = item.get('source').url_;

                    let image_source = layer_url + '?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=' + layer_name;

                    let div = document.getElementById('ol-legendInfoDiv-2');
                    div.innerHTML = '<img src="' + image_source + '" alt="Legend" >';
                    div.classList.add('ol-legend-info-show');
                }
            });
        });

        try {
            t.state.map_climate_forecast.removeControl(t.state.map_climate_forecast_LayerPopup_control);
            t.state.map_climate_forecast_LayerPopup_control._layers.forEach(function (item) {
                item.layer.values_.title = t.props.translate(item.layer.values_.id);
            });
            t.state.map_climate_forecast.addControl(t.state.map_climate_forecast_LayerPopup_control);
        } catch (e) {
        }
    }

    componentDidMount() {
        let t = this;

        let wmsSource = config.layer_station;

        let InfoControl = new StationInfoControl();

        let LegendControl = new LegendInfoControl();

        let ModalControl = new ModalInfoControl();

/////// Stations map ///////////////////////////////////////////////////////////////////////////////////////////////////
        let map_stations_view = new View({center: config.xy, zoom: config.zoom, extent: config.extent});
        let map_stations_LayerPopup = new LayerPopup();
        let map_stations_LoadingProgressControl = new LoadingProgress();
        let map_stations = new Map({
            controls: defaultControls().extend([
                new FullScreen({source: 'fullscreen-1'}),
                map_stations_LayerPopup,
                InfoControl,
                new ZoomAll({xy: config.xy, zoom: config.zoom, extra: -1}),
                map_stations_LoadingProgressControl
            ]),
            layers: config.layers_stations,
            target: 'map_stations',
            view: map_stations_view
        });

        map_stations_LoadingProgressControl.set();

        map_stations.on('singleclick', function (evt) {
            let viewResolution =/** @type {number} */
                (map_stations_view.getResolution());

            const layers = map_stations.getLayers();
            layers.forEach(function (item) {
                if (item.get('baseLayer') && item.get('visible')) {
                    let url = null;
                    if(item.get('id') === 'userreports'){
                        url = item.getSource().getGetFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {'INFO_FORMAT': 'application/json'});
                    }else{
                        url = wmsSource.getGetFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {'INFO_FORMAT': 'application/json'});
                    }
                    if (url) {
                        fetch(config.proxy + url + '&country=CY&type=wms', {
                            mode: 'cors',
                            headers: {
                                "Accept": "application/json",
                                "Content-Type": "application/json"
                            }
                        }).then(function (response) {
                            return response.json();
                        }).then(function (featureJson) {
                            if(item.get('id') === 'userreports') {
                                try {
                                    let featuresToshow = [
                                        {
                                            title: t.props.translate('date'),
                                            value: moment(featureJson.features[0].properties.date_insert).format('DD/MM/YYYY HH:mm')
                                        },
                                        {
                                            title: t.props.translate('event_type'),
                                            value: t.props.translate(featureJson.features[0].properties.event_type )
                                        }, {
                                            title: 'photo',
                                            value: featureJson.features[0].properties.photo
                                        }
                                    ];
                                    InfoControl.add(featuresToshow);

                                } catch (err) {
                                }
                            }else{
                                try {
                                    let featuresToshow = [
                                        {
                                            title: t.props.translate('stationNameEn'),
                                            value: featureJson.features[0].properties.stationid
                                        }, {
                                            title: t.props.translate('longitude'),
                                            value: featureJson.features[0].properties.lon
                                        }, {
                                            title: t.props.translate('latitude'),
                                            value: featureJson.features[0].properties.lat
                                        }, {
                                            title: t.props.translate('altitude'),
                                            value: featureJson.features[0].properties.alt
                                        }, {
                                            title: t.props.translate('date'),
                                            value: t.state.timestamp
                                        }
                                    ];
                                    InfoControl.add(featuresToshow);

                                } catch (err) {
                                }
                            }

                        });
                    }
                }
            });
        });


/////// Forecast map ///////////////////////////////////////////////////////////////////////////////////////////////////
        let map_forecast_view = new View({center: config.xy, zoom: config.zoom, extent: config.extent});
        let map_forecast_LayerPopup = new LayerPopup();
        let map_forecast_LoadingProgressControl = new LoadingProgress();
        let map_forecast = new Map({
            controls: defaultControls().extend([
                new FullScreen({source: 'fullscreen-2'}),
                map_forecast_LayerPopup,
                LegendControl,
                new ZoomAll({xy: config.xy, zoom: config.zoom, extra: -1}),
                ModalControl,
                map_forecast_LoadingProgressControl
            ]),
            layers: config.layers_forecasts,
            target: 'map_forecast',
            view: map_forecast_view
        });
        LegendControl.set(map_forecast);
        map_forecast_LoadingProgressControl.set();

        let start_info = true;
        map_forecast.on('singleclick', function (evt) {

            ModalControl.hide();

            if(start_info) {

                document.getElementById('map_forecast_mask').style["display"] = "block";

                try{
                    let DIVtoRemove = document.getElementById("info-chart");
                    DIVtoRemove.parentNode.removeChild(DIVtoRemove);
                }catch (e) {
                }

                start_info = false;
                const layers = map_forecast.getLayers();
                layers.forEach(function (item) {
                    if (item.get('baseLayer') && item.get('visible')) {
                        let viewResolution =/** @type {number} */
                            (map_forecast_view.getResolution());
                        let url = item.getSource().getGetFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {'INFO_FORMAT': 'application/json'});
                        if (url) {
                            fetch(config.proxy + url + '&country=CY&type=wms', {
                                mode: 'cors',
                                headers: {
                                    "Accept": "application/json",
                                    "Content-Type": "application/json"
                                }
                            }).then(function (response) {
                                return response.json();
                            }).then(function (featureJson) {

                                // Create time series for feature info
                                let time_series = [];
                                for (let i = t.state.min; i <= t.state.max; i = i + 10800000) {
                                    time_series.push(i);
                                }

                                let promises_info = [];
                                time_series.forEach(function (step) {
                                    promises_info.push(
                                        fetch(config.proxy + url + '&time=' + moment(step).toISOString() + '&country=CY&type=wms', {
                                            mode: 'cors',
                                            headers: {
                                                "Accept": "application/json",
                                                "Content-Type": "application/json"
                                            }
                                        }).then(function (response) {
                                            return response.json();
                                        })
                                    )
                                });

                                try {
                                    let wgs84_coords = toLonLat([evt.coordinate[0], evt.coordinate[1]]);
                                    let click_time = moment(item.getSource().getParams().TIME).format('DD/MM/YYYY HH:mm');
                                    let click_value = featureJson.features[0].properties['GRAY_INDEX'].toFixed(2);

                                    let promises_info_results = [];
                                    Promise.all(promises_info).then(function (response) {
                                        response.forEach(function (value) {
                                            try {
                                                promises_info_results.push(value.features[0].properties);
                                            } catch (e) {
                                                promises_info_results.push({GRAY_INDEX: null});
                                            }
                                        });

                                        let chart_data_tmp = [];
                                        for (let j = 0; j < time_series.length; j++) {
                                            chart_data_tmp.push([
                                                moment(time_series[j]),
                                                promises_info_results[j]['GRAY_INDEX']
                                            ])
                                        }
                                        let chart_data = chart_data_tmp.filter(function (value, index, arr) {
                                            return value[1] !== null;
                                        });

                                        ModalControl.set(
                                            "<div id='info-chart'><div style='font-size: large;'><b>" + item.values_.title + " (" + item.values_.unit + ")</b></div>" +
                                            "<div><b>" + t.props.translate("longitude") + ": </b>" + wgs84_coords[1].toFixed(3) + " - <b>" + t.props.translate("latitude") + ": </b> " + wgs84_coords[0].toFixed(3) + "</div>" +
                                            // "<div><b>" + t.props.translate("date") + ": </b>" + click_time + "</div>" +
                                            "<div id='chart' style='position: absolute; top: 50px; left: 0; right: 0; bottom: 0; padding: 20px;'></div></div>");
                                        ModalControl.show();

                                        let type = "line";
                                        if (item.values_.id === 'rain') {
                                            type = "bar";
                                        }

                                        let categories = [];
                                        let data = [];

                                        for(let i = 0; i < chart_data.length; i++){
                                            categories.push(chart_data[i][0]);
                                            data.push(chart_data[i][1]);
                                        }

                                        let options = {
                                            chart: {
                                                id: 'apexchart-forecast',
                                                toolbar: {
                                                    show: true,
                                                    tools: {
                                                        download: false
                                                    }
                                                }
                                            },
                                            stroke: {
                                                width: 2,
                                                curve: 'smooth'
                                            },
                                            tooltip: {
                                                x: {
                                                    format: 'dd MMM yyyy'
                                                }
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },
                                            yaxis: {
                                                title: {
                                                    text: item.values_.title + " (" + item.values_.unit + ")"
                                                }
                                            },
                                            xaxis: {
                                                categories: categories,
                                                title: {
                                                    text: t.props.translate("date")
                                                },
                                                labels: {
                                                    formatter: function(value, timestamp, index) {
                                                        if(value === undefined || value === null ){
                                                            return ''
                                                        }else{
                                                            return value.format("DD/MM HH:mm")
                                                        }
                                                    }
                                                }
                                            }
                                        };

                                        let series = [{
                                            name: item.values_.title + " (" + item.values_.unit + ")",
                                            data: data
                                        }];

                                        let chrt = <Chart options={options} series={series} type={type} width={'100%'} height={'100%'} />;

                                        ReactDOM.render(chrt, document.getElementById('chart'));

                                        start_info = true;

                                    });

                                } catch (e) {
                                    start_info = true;
                                    document.getElementById('map_forecast_mask').style["display"] = "none";
                                }

                            });
                        }
                    }
                });
            }
        });

        config.layers_forecasts.forEach(function (ly) {
            ly.on('change:visible', function () {
                if (ly.get('visible')) {
                    let layer_name = ly.get('source').getParams().LAYERS;
                    t.layerDIM.forEach(function (layer) {
                        if (layer.layer === layer_name) {
                            console.log(layer.layer)
                            console.log(layer_name)
                            if (layer_name === 'CYtwb2' || layer_name === 'CYt2m' || layer_name === 'CYwind' ||
                                layer_name === 'CYrh2' || layer_name === 'CYrain') {
                                t.setState({
                                    min: layer.min,
                                    max: layer.max,
                                    step: 10800000
                                });
                            } else {
                                t.setState({
                                    min: layer.min,
                                    max: layer.max,
                                    step: 10800000 * 8
                                });
                            }
                        }
                    })
                }
            });
        });


/////// Climate forecast map ///////////////////////////////////////////////////////////////////////////////////////////
        let map_climate_forecast_view = new View({center: config.xy, zoom: config.zoom, extent: config.extent});
        let map_climate_forecast_LayerPopup = new LayerPopup();
        let map_climate_LoadingProgressControl = new LoadingProgress();
        let LegendControlClimateForecast = new LegendInfoControl2();
        let map_climate_forecast = new Map({
            controls: defaultControls().extend([
                new FullScreen({source: 'fullscreen-3'}),
                new ZoomAll({xy: config.xy, zoom: config.zoom, extra: -1}),
                map_climate_forecast_LayerPopup,
                map_climate_LoadingProgressControl,
                LegendControlClimateForecast
            ]),
            layers: config.layers_climate_forecasts,
            target: 'map_climate_forecast',
            view: map_climate_forecast_view
        });
        map_climate_LoadingProgressControl.set();


        t.setState({
            map_stations: map_stations,
            map_stations_LayerPopup_control: map_stations_LayerPopup,
            map_forecast: map_forecast,
            map_forecast_LayerPopup_control: map_forecast_LayerPopup,
            map_climate_forecast: map_climate_forecast,
            map_climate_forecast_LayerPopup_control: map_climate_forecast_LayerPopup
        }, function () {
            t.updateLayers();
        });

        setTimeout(function () {
            map_stations.updateSize();
            map_forecast.updateSize();
            map_climate_forecast.updateSize();
            map_stations.getView().setZoom(map_stations.getView().getZoom() - 1);
            map_forecast.getView().setZoom(map_forecast.getView().getZoom() - 1);
            map_climate_forecast.getView().setZoom(map_climate_forecast.getView().getZoom() - 1);

            //Set layers translations
            config.layers_stations.forEach(function (item) {
                item.values_.title = t.props.translate(item.get('id'));
            });
            config.layers_forecasts.forEach(function (item) {
                item.values_.title = t.props.translate(item.get('id'));
            });
            config.layers_climate_forecasts.forEach(function (item) {
                item.values_.title = t.props.translate(item.get('id'));
            });

        }, 1000);

        this.GetTableData();

        this.ReadCapabilities();

    }

    GetTableData() {
        let t = this;
        fetch(config.proxy + config.stations_json + '&country=CY&type=wfs', {
            mode: 'cors',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        }).then(function (response) {
            return response.json();
        }).then(function (featureJson) {
            let a = [];
            let c = null;
            let d = null;
            let e = null;
            let f = null;
            let tm = 3;
            featureJson.features.forEach(function (item) {
                item.properties.geometry = item.geometry;
                a.push(item.properties);
                c = item.properties.date.replace('Z', '').split('-')[0] + '-' +
                    item.properties.date.replace('Z', '').split('-')[1] + '-' +
                    item.properties.date.replace('Z', '').split('-')[2];
                e = moment(c).add(1, 'd').format('YYYY-MM-DD');
                if(moment().isDST()){
                    tm = 2
                }
                // f = parseInt(item.properties.time.replace('Z', '').split(':')[0]) - 1;
                f = parseInt(item.properties.time.replace('Z', '').split(':')[0]) + tm;
                d = f + ':' + item.properties.time.replace('Z', '').split(':')[1] + '';
            });

            // t.setState({stations: a, timestamp: momenttz.tz(e + ' ' + d, "Asia/Nicosia").format('DD/MM/YYYY HH:mm')})
            t.setState({stations: a, timestamp: moment(e + ' ' + d).format('DD/MM/YYYY HH:mm')})
        });
    }

    ReadCapabilities() {
        let t = this;
        let parser = new WMSCapabilities();

        fetch(config.proxy + 'country=CY&type=getCapabilities').then(function (response) {
            return response.text();
        }).then(function (text) {
            let result = parser.read(text);
            const layers = result.Capability.Layer.Layer;
            let min = null;
            let max = null;
            layers.forEach(function (item) {
                try {
                    min = moment(item.Dimension[0].values.split('/')[0]).valueOf();
                    max = moment(item.Dimension[0].values.split('/')[1]).valueOf();
                    t.layerDIM.push({layer: item.Name, min: min, max: max});
                } catch (e) {
                }
            });
            layers.forEach(function (item) {
                try {
                    if (moment(item.Dimension[0].values.split('/')[0]).valueOf() < min) {
                        min = moment(item.Dimension[0].values.split('/')[0]).valueOf();
                    }
                    if (moment(item.Dimension[0].values.split('/')[1]).valueOf() > max) {
                        max = moment(item.Dimension[0].values.split('/')[1]).valueOf();
                    }
                } catch (e) {
                }
            });

            let now = moment().format('YYYY-MM-DD');
            t.setState({
                min: moment(now.toString() + 'T03:00:00').valueOf(),
                max: max
            })
        });
    }

    componentDidUpdate() {
        let t = this;
        try {
            t.state.map_stations.removeControl(t.state.map_stations_LayerPopup_control);
            t.state.map_stations_LayerPopup_control._layers.forEach(function (item) {
                item.layer.values_.title = t.props.translate(item.layer.values_.id);
            });
            t.state.map_stations.addControl(t.state.map_stations_LayerPopup_control);
        } catch (e) {
        }
        try {
            t.state.map_forecast.removeControl(t.state.map_forecast_LayerPopup_control);
            t.state.map_forecast_LayerPopup_control._layers.forEach(function (item) {
                item.layer.values_.title = t.props.translate(item.layer.values_.id);
            });
            t.state.map_forecast.addControl(t.state.map_forecast_LayerPopup_control);
        } catch (e) {
        }
        try {
            t.state.map_climate_forecast.removeControl(t.state.map_climate_forecast_LayerPopup_control);
            t.state.map_climate_forecast_LayerPopup_control._layers.forEach(function (item) {
                item.layer.values_.title = t.props.translate(item.layer.values_.id);
            });
            t.state.map_climate_forecast.addControl(t.state.map_climate_forecast_LayerPopup_control);
        } catch (e) {
        }

        // setTimeout(function () {
        //     window.scrollTo(0, 0);
        // }, 1000)

    }

    render() {
        let t = this;
        let columns = [
            {
                dataField: 'id',
                text: this.props.translate("actions"),
                sort: false,
                align: 'center',
                formatter: function (cell, row) {
                    return (<span>
              <FontAwesomeIcon icon={faSearchPlus}/>
            </span>);
                }
            }, {
                dataField: 'stationid',
                text: this.props.translate("stationNameEn"),
                sort: true
            }, {
                dataField: 'lat',
                text: this.props.translate("latitude"),
                sort: true
            }, {
                dataField: 'lon',
                text: this.props.translate("longitude"),
                sort: true
            }, {
                dataField: 'alt',
                text: this.props.translate("altitude"),
                sort: true
            }, {
                dataField: 'fireindex4',
                text: this.props.translate("FFWI"),
                sort: true
            }, {
                dataField: 'fireindex2',
                text: this.props.translate("FFDI"),
                sort: true
            }, {
                dataField: 'fireindex3',
                text: this.props.translate("GFDI5"),
                sort: true
            }, {
                dataField: 'fireindex1',
                text: this.props.translate("F"),
                sort: true
            }
        ];
        let pagination = {
            sizePerPageList: [
                {
                    text: '5',
                    value: 5
                }, {
                    text: '10',
                    value: 10
                }, {
                    text: '20',
                    value: 20
                }
            ]
        };
        const {SearchBar} = Search;
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                const coords = fromLonLat([
                    row.geometry.coordinates[0], row.geometry.coordinates[1]
                ]);
                t.state.map_stations.getView().animate({center: coords, zoom: 14});
            }
        };
        const expandRow = {
            renderer: row => (<div>
                <table className="ol-station-info-table" style={{marginLeft: '31px'}}>
                    <tbody>
                    <tr>
                        <td><b><Translate id="temperature"/>: </b>{row.temp}</td>
                        <td><b><Translate id="dailyMinTemperature"/>: </b>{row.dailymintemp}</td>
                        <td><b><Translate id="dailyMaxTemperature"/>: </b>{row.dailymaxtemp}</td>
                    </tr>
                    <tr>
                        <td><b><Translate id="windSpeed"/>: </b>{row.windspeed}</td>
                        <td><b><Translate id="windDirection"/>: </b>{row.winddir}</td>
                        <td><b><Translate id="maxWindSpeed"/>: </b>{row.windgusts}</td>
                    </tr>
                    <tr>
                        <td><b><Translate id="dew"/>: </b>{row.dew}</td>
                        <td><b><Translate id="bar"/>: </b>{row.pressure}</td>
                        <td><b><Translate id="solarRad"/>: </b>{row.solarrad}</td>
                    </tr>
                    <tr>
                        <td><b><Translate id="dailyRain"/>: </b>{row.dailyrain}</td>
                        <td><b><Translate id="monthlyRain"/>: </b>{row.monthlyrain}</td>
                        <td><b><Translate id="hum"/>: </b>{row.relhum}</td>
                    </tr>
                    </tbody>
                </table>
            </div>),
            showExpandColumn: true,
            onExpand: (row, isExpand, rowIndex, e) => {
            },
            onExpandAll: (isExpandAll, rows, e) => {
            },
            expandHeaderColumnRenderer: ({isAnyExpands}) => {
                if (isAnyExpands) {
                    return <b>-</b>;
                }
                return <b>+</b>;
            },
            expandColumnRenderer: ({expanded}) => {
                if (expanded) {
                    return (<b>-</b>);
                }
                return (<b>...</b>);
            }
        };

        return (<div>

            <div className="container">

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" className="p-2 text-dark"><Translate
                            id="home"/></Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Translate id="cyprus"/></li>
                    </ol>
                </nav>

                <div className="card">
                    <h5 className="card-header"><Translate id="liveStations"/></h5>
                    <div className="card-body">
                        <div id="fullscreen-1" className="fullscreen">
                            <div id="map_stations" className="map"/>
                            <div id="timestamp">{this.state.timestamp}</div>
                            <div className="map-table">
                                <ToolkitProvider keyField="id" data={this.state.stations} columns={columns}
                                                 exportCSV search>
                                    {
                                        props => (<div className="react-bs-table-container">
                                            <div className="row" style={{
                                                paddingTop: '1em'
                                            }}>
                                                <div className="col-auto mr-auto">
                                                    <div className="col-auto mr-auto" style={{fontSize: "larger"}}>
                                                        <Link to="/About" className="react-bs-table-about"><Translate id="about_parameter"/></Link>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <SearchBar {...props.searchProps} style={{
                                                        width: '15em'
                                                    }}/>
                                                </div>
                                            </div>
                                            <hr/>
                                            <BootstrapTable {...props.baseProps}
                                                            pagination={paginationFactory(pagination)}
                                                            rowEvents={rowEvents} expandRow={expandRow}
                                                            striped hover condensed/>
                                        </div>)
                                    }
                                </ToolkitProvider>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card" style={{
                    marginTop: "3em",
                    marginBottom: "3em"
                }}>
                    <h5 className="card-header"><Translate id="activeFireMonitoring"/></h5>
                    <div className="card-body">
                        <div className="row" style={{height: "25em"}}>
                            <div className="col-sm">
                                <h6><Translate id="FireRadiativePower"/></h6>
                                <div id="iframe_1" className="fullScreenFrameDiv">
                                    <button id="iframe_1_btn" type="button"
                                            className="fas fa-expand-arrows-alt frameBtn"
                                            onClick={() => {
                                                document.getElementById("iframe_1").classList.toggle("fullScreenFrame");
                                                document.getElementById("iframe_1_btn").classList.toggle("fullScreenFrameBtn");
                                            }}/>
                                    <iframe title="noa" src="http://stratus.meteo.noa.gr/saf/frp/cy" width="100%"
                                            height="100%"/>
                                </div>
                            </div>
                            <div className="col-sm">
                                <h6><Translate id="FireDetection"/></h6>
                                <div id="iframe_2" className="fullScreenFrameDiv">
                                    <button id="iframe_2_btn" type="button"
                                            className="fas fa-expand-arrows-alt frameBtn"
                                            onClick={() => {
                                                document.getElementById("iframe_2").classList.toggle("fullScreenFrame");
                                                document.getElementById("iframe_2_btn").classList.toggle("fullScreenFrameBtn");
                                            }}/>
                                    <iframe title="noa" src="http://stratus.meteo.noa.gr/saf/firex/cy" width="100%"
                                            height="100%"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card" style={{
                    marginTop: "3em",
                    marginBottom: "3em"
                }}>
                    <h5 className="card-header"><Translate id="cloudTopsLightningActivity"/></h5>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm" align="center">
                                <video controls="controls" autoPlay="autoplay" loop="loop" className="safvideo"
                                       width="50%">
                                    <source
                                        src="http://stratus.meteo.noa.gr/forecast/safnwc/NWCSAF_ZEUS_2.mp4?v=0.683150540367"
                                        type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <h5 className="card-header"><Translate id="forecasts"/></h5>
                    <div className="card-body">
                        <div id="fullscreen-2" className="fullscreen">
                            <div id="map_forecast" className="map"/>
                            <div id='map_forecast_mask'><PixelSpinner color="white"/></div>
                            <TimeSlider
                                min={this.state.min}
                                max={this.state.max}
                                step={this.state.step}
                                map={this.state.map_forecast}
                            />
                        </div>
                    </div>
                </div>

                <div className="card" style={{marginTop: "3em"}}>
                    <h5 className="card-header"><Translate id="climateForecasts"/></h5>
                    <div className="card-body">
                        <div id="fullscreen-3" className="fullscreen">
                            {
                                this.state.map_climate_forecast !== null
                                    ?
                                    <div style={{paddingBottom: '1em'}}>
                                        <Row>
                                            <Col>
                                                <Label for="ClimateModel"><Translate id="ClimateModel"/></Label>
                                                <Input type="select" id="ClimateModel" name="ClimateModel" bsSize="sm"
                                                       onChange={this.setClimateModel}>
                                                    <option value="cnrm">{this.props.translate("cnrm")}</option>
                                                    <option value="mohc">{this.props.translate("mohc")}</option>
                                                    <option value="mpi">{this.props.translate("mpi")}</option>
                                                </Input>
                                            </Col>
                                            <Col>
                                                <Label for="EmissionScenario"><Translate id="EmissionScenario"/></Label>
                                                <Input type="select" id="EmissionScenario" name="EmissionScenario"
                                                       bsSize="sm"
                                                       onChange={this.setEmissionScenario}>
                                                    <option value="rcp45">{this.props.translate("rcp45")}</option>
                                                    <option value="rcp85">{this.props.translate("rcp85")}</option>
                                                </Input>
                                            </Col>
                                            <Col>
                                                <Label for="FireDangerIndicator"><Translate id="FireDangerIndicator"/></Label>
                                                <Input type="select" id="FireDangerIndicator" name="FireDangerIndicator"
                                                       bsSize="sm"
                                                       onChange={this.setFireDangerIndicator}>
                                                    <option value="CFWI">{this.props.translate("CFWI2")}</option>
                                                    <option value="FFWI">{this.props.translate("FFWI2")}</option>
                                                    <option value="SAI">{this.props.translate("SAI2")}</option>
                                                    <option value="SPI">{this.props.translate("SPI2")}</option>
                                                </Input>
                                            </Col>
                                            <Col>
                                                <Label for="FireDanger"><Translate id="FireDanger"/></Label>
                                                <Input type="select" id="FireDanger" name="FireDanger"
                                                       bsSize="sm"
                                                       onChange={this.setFireDanger}>
                                                    {
                                                        this.state.FireDangerOptions ? this.state.FireDangerOptions
                                                            :
                                                            <>
                                                                <option value="MeanSummerFireDanger">{t.props.translate('ModerateFireDanger')}</option>
                                                                <option value="ModerateFireDanger">{t.props.translate('ModerateFireDanger')}</option>
                                                                <option value="HigFireDanger">{t.props.translate('HigFireDanger')}</option>
                                                                <option value="VeryHighFireDanger">{t.props.translate('VeryHighFireDanger')}</option>
                                                            </>
                                                    }
                                                </Input>
                                            </Col>
                                            <Col>
                                                <Label for="Period"><Translate id="Period"/></Label>
                                                <Input type="select" id="Period" name="Period"
                                                       bsSize="sm"
                                                       onChange={this.setPeriod}>
                                                    <option value="ref">{this.props.translate("ref")}</option>
                                                    <option value="diff">{this.props.translate("diff")}</option>
                                                </Input>
                                            </Col>
                                        </Row>
                                    </div>
                                    :
                                    ''
                            }
                            <div id="map_climate_forecast" className="map"/>
                        </div>
                    </div>
                </div>

            </div>
        </div>);
    }
}

export default withLocalize(Cyprus);
